import React from 'react';
import { ReferenceIdTextInput } from './ReferenceIdTextInput';
import { Row } from '../Layout';

const IntegratedGiftCertificate = ({ transaction, setTransaction }) => {
  return (
    <Row>
      <ReferenceIdTextInput transaction={transaction} setTransaction={setTransaction} />
    </Row>
  );
};

export default IntegratedGiftCertificate;
